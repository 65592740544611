import { Component, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, distinctUntilChanged, take, filter, share } from 'rxjs/operators';
import { ConnectivityService } from '@core/services/connectivity/connectivity.service';
import { LS_KEYS, StorageService } from '@core/services/storage/storage.service';
import { fetchUser } from './store/actions/user.actions';
import { StoreState } from './store/state/store.state';
import { StatusBar, Style } from '@capacitor/status-bar';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './core/models/user.model';
import { IonApp, IonRouterOutlet, Platform, IonSplitPane, IonMenu } from '@ionic/angular/standalone';
import { selectValidDistinctLanguage, selectDistinctUserId, selectValidUser } from './store/selectors/user.selectors';
import { combineLatest, Observable } from 'rxjs';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { hideLoading } from '@store/actions/app.actions';
import { selectMenuStatus, selectValidDarkModeChanged, selectValidLoadingChanged } from '@store/selectors/app.selectors';
import { FilesFolderService } from '@pages/files-folder/services/files-folder.service';
import { UploadFileProgress } from '@core/models/files-folder.model';
import { BrowserTypes } from '@core/models/app.model';
import { checkSubscriptionStatus, fetchAvailableProducts } from '@store/actions/checkout.actions';
import { LoadingComponent } from '@core/components/loading/loading.component';
import { FileUploadProgressComponent } from '@core/components/file-upload-progress/file-upload-progress.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';
import { fetchConfigurations, fetchCountryConfigurations } from '@store/actions/cms.actions';
import { WINDOW } from 'custom-window';
import { AppService } from '@core/services/app/app.service';
import { PushNotificationsService } from '@core/services/plugin-services/push-notifications/push-notifications.service';
import { selectValidConfigurations } from '@store/selectors/cms.selectors';
import { MenuComponent } from '@core/components/menu/menu.component';
import { LetDirective } from '@ngrx/component';
import { calculateIsTabletOrBigger } from '@helpers/common.helpers';
import { ALL_ICONS } from '@assets/icons/app-icons';
import { Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'gd-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonMenu,
    MenuComponent,
    LoadingComponent,
    FileUploadProgressComponent,
  ],
})
export class AppComponent {

  /** App view container ref */
  @ViewChild('dialogInjection', { read: ViewContainerRef }) dialogInjection: ViewContainerRef;

  /** Observable to indicate if show loading */
  public showLoading$: Observable<boolean>;
  /** Observable to indicate if collapse menu */
  public collapseMenu$: Observable<boolean>;
  /** File upload progress */
  public fileUploadProgress$: Observable<UploadFileProgress>;
  /** Indicates if screen is tablet or bigger size */
  public isTabletOrBigger: boolean;
  /** Indicates if should show menu */
  public shouldShowMenu: boolean;

  /** Dependencies */
  private platform = inject(Platform);
  private store = inject(Store<StoreState>);
  private translateService = inject(TranslateService);
  private filesService = inject(FilesFolderService);
  private storageService = inject(StorageService);
  private cookiesService = inject(NgcCookieConsentService);
  private adsService = inject(AdsService);
  private pushNotificationsService = inject(PushNotificationsService);
  private appService = inject(AppService);
  private router = inject(Router);
  protected document = inject(DOCUMENT);
  protected window = inject(WINDOW);

  constructor() {
    this.isTabletOrBigger = calculateIsTabletOrBigger(this.window.innerWidth, this.window.innerHeight);

    // Load app icons
    this.loadIcons();

    this.platform.ready().then(() => {
      // Subscriptions
      this.showLoading$ = this.store.pipe(selectValidLoadingChanged);
      this.collapseMenu$ = this.store.select(selectMenuStatus);
      this.fileUploadProgress$ = this.filesService.fileUploadProgress$;
      this.setConfigurationsSubscription();
      this.setUserSubscription();
      this.setRouterSubscription();
      this.setDarkModeSubscription();
      this.setPdfViewerSubscription();
      
      // Initial actions
      this.store.dispatch(fetchConfigurations());
      this.store.dispatch(fetchCountryConfigurations());
      this.setLanguage();
      this.initCookies();
      this.fetchUserIfLogged();
      
      // Platform actions
      this.doWebActions();
      this.doDeviceActions();
    });
  }

  /**
   * Configure app language
   */
  public setLanguage() {
    const browserLanguage = SUPPORTED_LANGUAGES.find(el => el === this.translateService.getBrowserLang()) || DEFAULT_LANGUAGE;
    this.store
      .pipe(
        selectValidDistinctLanguage,
        map(language => language || this.storageService.getItem(LS_KEYS.LANGUAGE) || browserLanguage),
        startWith(this.storageService.getItem(LS_KEYS.LANGUAGE) || browserLanguage),
      )
      .subscribe((language: string) => {
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
        this.storageService.setItem(LS_KEYS.LANGUAGE, language);
      });
  }

  /**
   * Set configurations subscription
   */
  private setConfigurationsSubscription() {
    this.store
      .pipe(
        selectValidConfigurations,
        take(1),
      )
      .subscribe((configurations) => {
        // Load css fixes
        this.loadFixesCss(configurations.fixesCss);
      });
  }

  /**
   * Load css fixes
   */
  private loadFixesCss(fixesCss: string) {
    const styleId = 'css-fixes';
    const styleElement = document.createElement('style');
    styleElement.setAttribute('id', styleId);
    styleElement.innerHTML = fixesCss;

    // Remove if exists
    const existingStyleElement = document.getElementById(styleId);
    if (existingStyleElement) {
      existingStyleElement.remove();
    }

    // Append element to head
    document.head.appendChild(styleElement);
  }

  /**
   * Fetch user if has a valid token
   */
  private fetchUserIfLogged() {
    const token = this.storageService.getItem(LS_KEYS.TOKEN);
    if (!token || token === 'undefined') { return; }
    this.store.dispatch(fetchUser({}));
  }

  /**
   * Fetch subscription, products and config if user is logged
   */
  private setUserSubscription() {
    this.getUserSubscription().subscribe((user) => {
      // Fetch available products
      if (user?.accountData?.isAccountActivated) {
        this.store.dispatch(fetchAvailableProducts());
      }

      // Check subscription status
      if (user?.accountData?.userType === 'workshop' || user?.accountData?.isFleet) {
        this.store.dispatch(checkSubscriptionStatus({ showLoading: false }));
      }
    });
  }

  /**
   * Set router subscription
   */
  private setRouterSubscription() {
    combineLatest([
      this.router.events.pipe(filter(event => event instanceof RoutesRecognized)),
      this.getUserSubscription(),
    ]).subscribe(([data, user]) => {
      const routeData = (data as RoutesRecognized).state.root.firstChild.data;
      const isLandingPageMode = routeData.landingPageMode === true;
      this.shouldShowMenu = !isLandingPageMode && !!user?.id;
      this.document.body.classList.toggle('landing', isLandingPageMode);
    });
  }

  /**
   * Get user subscription
   */
  private getUserSubscription() {
    return this.store.pipe(
      selectDistinctUserId,
      share(),
    );
  }

  /**
   * Set pdf viewer subscription
   */
  private setPdfViewerSubscription() {
    this.filesService.showPdfViewer$.subscribe(async ({ file, fileUrl, isLocal }) => {
      const { PdfViewerWrapperComponent } = await import('@shared/components/pdf-viewer-wrapper/pdf-viewer-wrapper.component');
      this.appService.emitVibration();
      const componentRef = this.dialogInjection.createComponent(PdfViewerWrapperComponent);
      componentRef.instance.file = file;
      componentRef.instance.fileUrl = fileUrl;
      componentRef.instance.isLocal = isLocal;
      componentRef.instance.closeViewer.subscribe(() => componentRef.destroy());
    });
  }

  /**
   * Check if dark mode is enabled and set
   */
  private setDarkModeSubscription() {
    this.store
      .pipe(
        selectValidDarkModeChanged,
        startWith(false),
        distinctUntilChanged(),
      )
      .subscribe(isDarkMode => {
        // Set dark mode class on body
        this.document.body.classList.toggle('dark', isDarkMode);
        // Set status bar style on ios (device info)
        if (Capacitor.getPlatform() === 'ios') { StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light }); }
        // Set status bar style on android (device info)
        if (Capacitor.getPlatform() === 'android') { StatusBar.setStyle({ style: Style.Default }); }
      });
  }

  /**
   * Init cookies service
   */
  private initCookies() {
    const hasCookiesAccepted = !!this.storageService.getItem(LS_KEYS.COOKIES_ACCEPT);
    if (hasCookiesAccepted) {
      // Initialize ads service if cookies has been accepted
      this.adsService.initialize();
      return;
    }

    this.cookiesService.open();
    this.cookiesService.statusChange$
      .pipe(take(1))
      .subscribe((event: NgcStatusChangeEvent) => {
        if (event.status === 'allow') {
          this.storageService.setItem(LS_KEYS.COOKIES_ACCEPT, 'true');
        }
        // Initialize ads on close cookies pop up
        this.adsService.initialize();
        // Destroy service instance
        this.cookiesService.destroy();
      });
  }

  /**
   * Save browser name in window
   */
  private saveBrowserName() {
    const userAgent = navigator.userAgent;
    let browserName: BrowserTypes;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    }
    this.window.browser = browserName;
  }

  /**
   * Web initial actions
   */
  private doWebActions() {
    if (Capacitor.getPlatform() === 'web') {
      // Save browser name in window
      this.saveBrowserName();
    }
  }

  /**
   * Device initial actions
   */
  private async doDeviceActions() {
    if (Capacitor.getPlatform() !== 'web') {
      const { SplashScreen } = await import('@capacitor/splash-screen');
      const { Keyboard } = await import('@capacitor/keyboard');

      // Hide splash screen on load app
      SplashScreen.hide();
      // Keyboard config for mobile devices
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }

  /**
   * Load icons
   */
  private async loadIcons() {
    const { addIcons } = await import('ionicons');
    addIcons({ ...ALL_ICONS });
  }

}
